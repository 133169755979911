import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import { Dropdown } from '..'

export const MobileMenu = ({ menu }) => {
  return (
    <nav>
      <MenuWrapper>
        {menu?.map(({ id, path, label, children }) => (
          <li key={id}>
            {!children?.length ? (
              <MenuItem to={path}>{label}</MenuItem>
            ) : (
              <Dropdown {...{ label, children }} />
            )}
          </li>
        ))}
      </MenuWrapper>
    </nav>
  )
}

const MenuWrapper = styled.ul`
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin: 2rem 0 0 30px;
  padding: 0;
`

const MenuItem = styled(Link)`
  color: #000;
  text-decoration: none;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 100%;
    position: absolute;
    bottom: -3px;
    left: -6px;
    background: #000;
    height: 2px;
  }
`

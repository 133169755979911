import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useState } from 'react'
import { MenuItem } from '..'
import { theme } from '../../assets/theme'

export const Dropdown = ({ label, children }) => {
  const [open, setOpen] = useState(false)

  return (
    <ItemDropdown>
      <DropdownLabel onClick={() => setOpen(!open)}>
        <span>{label}</span>
        <Icon
          open={open}
          xmlns="http://www.w3.org/200Icon"
          width="14"
          height="8"
          viewBox="0 0 14 8">
          <path
            d="M2.1455 0.3548L7.00656 5.06893L11.8676 0.3548C11.9836 0.242315 12.1213 0.153087 12.2729 0.0922108C12.4244 0.0313339 12.5868 4.76837e-07 12.7509 4.76837e-07C12.9149 4.76837e-07 13.0773 0.0313339 13.2289 0.0922108C13.3804 0.153087 13.5182 0.242315 13.6341 0.3548C13.7501 0.467285 13.8421 0.600825 13.9049 0.747794C13.9677 0.894763 14 1.05228 14 1.21136C14 1.37044 13.9677 1.52796 13.9049 1.67493C13.8421 1.8219 13.7501 1.95544 13.6341 2.06792L7.88356 7.64469C7.76765 7.75732 7.62998 7.84668 7.47842 7.90765C7.32686 7.96862 7.16438 8 7.0003 8C6.83622 8 6.67374 7.96862 6.52218 7.90765C6.37062 7.84668 6.23295 7.75732 6.11704 7.64469L0.366458 2.06792C-0.122153 1.59408 -0.122153 0.828643 0.366458 0.3548C0.85507 -0.106893 1.65689 -0.119042 2.1455 0.3548Z"
            fill="white"
          />
        </Icon>
      </DropdownLabel>
      <DropdownList open={open}>
        {children.map(child => (
          <MenuItem to={child?.path} key={child?.id}>
            {child?.label}
          </MenuItem>
        ))}
      </DropdownList>
    </ItemDropdown>
  )
}

const ItemDropdown = styled.div`
  position: relative;
`

const DropdownList = styled.div`
  transition: all 0.15s ease-in-out;
  position: absolute;
  z-index: 10;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  background: #fff;
  color: #000;
  padding: 15px 25px;
  top: 1.5rem;
  left: -3rem;
  width: max-content;
  box-shadow: 4px 4px 0px rgba(0, 0, 0, 1);
  filter: drop-shadow(0px 4px 23px rgba(0, 0, 0, 0.07));

  ${MenuItem} {
    color: #000;
    font-size: 16px;
  }

  ${({ open }) => css`
    opacity: ${open ? '1' : '0'};
    z-index: ${open ? '10' : '-1'};
  `}

  ${() => theme.mqMax('sm')} {
    left: -5rem;
  }
`

const DropdownLabel = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Icon = styled.svg`
  ${() => theme.mqMax('md')} {
    path {
      fill: #000;
    }
  }

  ${({ open }) =>
    open
      ? css`
          transform: rotate(-90deg);
        `
      : css`
          transform: rotate(0deg);
        `}
`

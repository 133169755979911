import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React from 'react'

export const Drawer = ({ children, open, toggleDrawer }) => {
  return (
    <Overlay open={open} onClick={() => toggleDrawer(false)}>
      <Container onClick={event => event.stopPropagation()} open={open}>
        <Ssvg
          onClick={() => toggleDrawer(false)}
          xmlns="http://www.w3.org/2000/svg"
          height="512px"
          id="Layer_1"
          style={{ enableBackground: 'new 0 0 512 512' }}
          version="1.1"
          viewBox="0 0 512 512"
          width="512px">
          <path d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z" />
        </Ssvg>
        {children}
      </Container>
    </Overlay>
  )
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  /* opacity: 0; */
  background-color: rgba(0, 0, 0, 0.6);
  ${({ open }) =>
    open
      ? css`
          background-color: rgba(0, 0, 0, 0.6);
          z-index: 999999;
        `
      : css`
          background-color: rgba(0, 0, 0, 0);
          z-index: -10;
        `}
`

const Ssvg = styled.svg`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 1rem;
  right: 1rem;
`

const Container = styled.div`
  position: relative;
  padding: 2rem 0 0 2rem;
  width: 320px;
  height: 100%;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
  overflow-y: auto;
  color: #000;

  &::-webkit-scrollbar {
    width: 0;
  }
  ${({ open }) =>
    open
      ? css`
          transform: translate(0);
        `
      : css`
          transform: translate(-100vh);
        `}
`

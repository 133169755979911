import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import { theme } from '../../assets/theme'

export const useModal = () => {
  const [isOpen, setIsOpen] = React.useState(false)
  return {
    isOpen,
    onToggle: state => setIsOpen(state),
  }
}

export const Modal = ({
  open,
  onClose,
  children,
  width = '600px',
  height = '600px',
}) => {
  useEffect(() => {
    document.body.style.overflowY = open ? 'hidden' : 'auto'
  }, [open])

  return (
    <ModalOverlay open={open} onClick={() => onClose(false)}>
      <ModalContainer
        width={width}
        height={height}
        onClick={e => e.stopPropagation()}
        open={open}>
        {children}
      </ModalContainer>
    </ModalOverlay>
  )
}

const ModalOverlay = styled.div`
  top: 0;
  left: 0;
  z-index: 10;
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  ${({ open }) =>
    open
      ? css`
          background-color: rgba(0, 0, 0, 0.6);
          z-index: 1000;
        `
      : css`
          background-color: rgba(0, 0, 0, 0);
          z-index: -10;
        `}
`

const ModalContainer = styled.div`
  background: #fff;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  margin: auto;
  position: relative;
  overflow: hidden;
  -webkit-box-shadow: 5px 14px 51px 3px rgba(255, 255, 255, 0.2);
  -moz-box-shadow: 5px 14px 51px 3px rgba(255, 255, 255, 0.2);
  box-shadow: 5px 14px 51px 3px rgba(255, 255, 255, 0.2);
  ${() => theme.mqMax('lg')} {
    ${({ open }) =>
      open
        ? css`
            width: 95%;
            height: 90%;
            opacity: 1;
          `
        : css`
            width: 0%;
            height: 0%;
            opacity: 0;
          `}
  }
  ${({ open, width, height }) =>
    open
      ? css`
          width: ${width};
          height: ${height};
          opacity: 1;
        `
      : css`
          width: 0%;
          height: 0%;
          opacity: 0;
        `}
`

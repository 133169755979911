import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { theme } from '../../assets/theme'
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

const List = ({ items, entity, grid = false, locale }) => (
  <Items grid={grid}>
    {items?.map(({ node: item }) => (
      <Item to={`/${locale}/${entity}/${item.id}`} key={item.id}>
        <h4>{item?.title}</h4>
        <p>{item.description.slice(0, 100)}...</p>
        <ImageContainer>
          <ItemImage
            image={item.image.localFile.childImageSharp.gatsbyImageData}
            alt={item?.title}
          />
          {item?.created_at ? (
            <Date>
              {moment(item.created_at)
                .format('DD,MMM')
                .split(',')
                .map(item => (
                  <span>{item}</span>
                ))}
            </Date>
          ) : (
            ''
          )}
        </ImageContainer>
      </Item>
    ))}
  </Items>
)

export const ItemImage = styled(GatsbyImage)`
  position: relative;
  width: 100%;
  height: 100%;
  transition: all 0.3s ease-in-out;
`

export const ImageContainer = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 440px;
  overflow: hidden;

  &:hover ${ItemImage} {
    transform: scale(1.1);
  }

  ${() => theme.mqMax('lg')} {
    ${ItemImage} {
      max-width: 100%;
    }
    height: 200px;
  }
`

const Date = styled.div`
  position: absolute;
  bottom: 10px;
  left: 10px;
  padding: 5px;
  background: ${theme.colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  text-transform: capitalize;
`

const Item = styled(Link)`
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  text-decoration: none;
  color: ${() => theme.colors.black};
  p {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    order: -1;
    ${() => theme.mqMax('lg')} {
      margin: 0;
    }
  }

  h4 {
    font-size: 24px;
    margin: 1rem 0;
  }

  p,
  h4 {
    &:hover ~ ${ImageContainer} {
      ${ItemImage} {
        transform: scale(1.1);
      }
    }
  }

  &:hover > p {
    text-decoration: underline;
  }
`

const Items = styled.div`
  display: grid;
  width: 100%;
  gap: 2rem;

  ${({ grid }) =>
    grid
      ? css``
      : css`
          grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        `}
`

export { List }

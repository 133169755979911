import styled from '@emotion/styled'
import React from 'react'
import { theme } from '../../assets/theme'

export const PageTitle = ({ children, uppercase = false, isMargin = true }) => (
  <Title isMargin={isMargin} uppercase={uppercase}>
    {children}
  </Title>
)

const Title = styled.h3`
  font-size: 1.5rem;
  margin: 0;
  margin-bottom: ${({ isMargin }) => (isMargin ? '2rem' : '0')};
  text-transform: ${({ uppercase }) => (uppercase ? 'uppercase' : 'auto')};

  ${() => theme.mq('lg')} {
    font-size: 2.2rem;
    margin-bottom: ${({ isMargin }) => (isMargin ? '4rem' : '0')};
  }
`

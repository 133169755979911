import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import { ItemImage } from '.'

export const Carousel = ({ images, outsideCurrentIndex }) => {
  const [fullscreen, setFullscreen] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(null)

  const onKeyboardImageControl = e => {
    if (e.key === 'ArrowUp' && currentIndex > 0)
      setCurrentIndex(currentIndex - 1)
    if (e.key === 'ArrowDown' && currentIndex < images.length - 1)
      setCurrentIndex(currentIndex + 1)
  }

  useEffect(() => {
    if (outsideCurrentIndex === null) return
    setCurrentIndex(outsideCurrentIndex)
    setFullscreen(true)
  }, [outsideCurrentIndex])

  useEffect(() => {
    document.addEventListener('keydown', onKeyboardImageControl)
    return () => {
      document.removeEventListener('keydown', onKeyboardImageControl)
    }
  })

  const renderedSideImages = () =>
    images.map((item, index) => (
      <Image
        onClick={() => setCurrentIndex(index)}
        image={item.localFile.childImageSharp.gatsbyImageData}
        isCurrent={index === currentIndex}
      />
    ))

  const Image = props => <SItemImage {...props} />

  return fullscreen ? (
    <FullscreenOverlay onClick={() => setFullscreen(false)}>
      <FullscreenContainer onClick={e => e.stopPropagation()}>
        <SideList>{renderedSideImages()}</SideList>
        <Preview>
          <Image
            isCurrent
            image={
              images[currentIndex].localFile.childImageSharp.gatsbyImageData
            }
          />
        </Preview>
      </FullscreenContainer>
    </FullscreenOverlay>
  ) : (
    ''
  )
}
const FullscreenOverlay = styled.div`
  top: 0;
  left: 0;
  z-index: 10;
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.9);
`

const FullscreenContainer = styled.div`
  width: 80%;
  height: 80%;
  margin: auto;
  display: flex;
  gap: 1rem;
  position: relative;
  overflow: hidden;
`

const SideList = styled.div`
  width: 300px;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  &::-webkit-scrollbar {
    width: 0;
  }
`

const Preview = styled.div`
  width: max-content;
  height: 100%;
  background: #000;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SItemImage = styled(ItemImage)`
  overflow: initial;
  ${props =>
    props.isCurrent
      ? css`
          transform: scale(1);
          opacity: 1;
        `
      : css`
          transform: scale(0.9);
          opacity: 0.5;
        `};
`

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React, { useState } from 'react'
import { Container, ItemImage, ImageContainer } from '.'
import { Button, PageTitle } from '..'
import { theme } from '../../assets/theme'
import { useTranslation } from '../../views'

export const SingleItem = ({ data, path, reverse, locale }) => {
  const [hover, setHover] = useState(false)
  const { t } = useTranslation(locale)
  return (
    <SingleWrapper>
      <SContainer reverse={reverse}>
        <div>
          <PageTitle>{data?.title}</PageTitle>
          <p>{data?.subtitle}</p>
          <Link to={path} style={{ textDecoration: 'none' }}>
            <Button
              onMouseLeave={() => setHover(false)}
              onMouseEnter={() => setHover(true)}>
              {t('details')}
            </Button>
          </Link>
        </div>
        <Link to={path} style={{ textDecoration: 'none' }}>
          <SImageContainer isHover={hover}>
            <ItemImage
              image={data.preview.localFile.childImageSharp.gatsbyImageData}
              alt={data?.title}
            />
          </SImageContainer>
        </Link>
      </SContainer>
    </SingleWrapper>
  )
}

const SContainer = styled(Container)`
  gap: 2rem;
  flex-direction: ${({ reverse }) =>
    css`
      ${reverse ? 'row-reverse' : 'row'}
    `};

  ${() => theme.mqMax('lg')} {
    flex-direction: column-reverse;
  }
`
const SingleWrapper = styled.div`
  padding: 4rem 0;
  ${Container} {
    display: flex;
    gap: 5rem;
  }
  p {
    margin-bottom: 2.2rem;
    line-height: 28px;
    color: #1c1c1c;
  }

  ${() => theme.mqMax('lg')} {
    padding: 2rem 0;
  }
`

const SImageContainer = styled(ImageContainer)`
  min-width: 420px;

  ${ItemImage} {
    ${({ isHover }) =>
      isHover
        ? css`
            transform: scale(1.1);
          `
        : css`
            transform: scale(1);
          `}
  }

  ${() => theme.mqMax('lg')} {
    min-width: auto;
    height: 300px;
  }
`

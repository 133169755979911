import * as React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from '../../views'

export const Seo = ({
  description = 'hello',
  meta = {},
  pageContext,
  locale,
  title = 'hello',
}) => {
  const { mainTitle, pageTitle } = meta
  const metaDescription = description
  const { t } = useTranslation(pageContext?.locale || locale)
  // metaTitle = title ||
  return (
    <Helmet
      title={pageTitle || mainTitle || t('courtyard')}
      titleTemplate={pageTitle ? `%s | ${mainTitle}` : null}
      meta={[
        ...[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: title,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
        ],
      ]}
    />
  )
}

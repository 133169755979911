import styled from '@emotion/styled'
import React from 'react'
import { YMaps, Map as YMap, Placemark } from 'react-yandex-maps'

export const Map = ({ coords, zoom }) => {
  const c = coords.split(',').map(item => item.trim())
  return (
    <YMaps query={{ lang: 'ru_RU' }}>
      <MapContainer>
        <YMap
          style={contentMap}
          defaultState={{ controls: false, center: c, zoom: zoom || 9 }}>
          <Placemark geometry={c} />
        </YMap>
      </MapContainer>
    </YMaps>
  )
}

const contentMap = {
  height: '100%',
  width: '100%',
}

const MapContainer = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
`

const breakpoints = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
}

const mq = (breakpoint) => `@media (min-width: ${breakpoints[breakpoint]}px)`

const mqMax = (breakpoint) => `@media (max-width: ${breakpoints[breakpoint] - 0.0001}px)`

export const theme = {
  mq,
  mqMax,
  breakpoints,
  colors: {
    primary: '#CAC297',
    secondary: '#7e55d9',
    background: '#191a1d',
    success: '#32a071',
    error: '#f80000',
    black: '#141414'
  },
}

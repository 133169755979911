import styled from '@emotion/styled'
import React, { useState } from 'react'
import { FlexRow, FlexCol } from '.'
import { theme } from '../../assets/theme'
export const Gallery = ({ how }) => {
  const [active, setActive] = useState(how?.itemlist[0])

  return (
    <Wrapper>
      <MediaWrapper>
        {active?.media?.mime.includes('image/') ? (
          <img
            alt="img"
            src={`https://admin.afonpodvorie.com${active?.media?.url}`}
          />
        ) : (
          ''
        )}
        {active?.media?.mime.includes('video/') ? (
          <video
            alt="img"
            controls
            src={`https://admin.afonpodvorie.com${active?.media?.url}`}></video>
        ) : (
          ''
        )}
      </MediaWrapper>
      <InfoBlock>
        <h3>{how?.title}</h3>
        {how?.itemlist?.map((item, key) => (
          <Step
            active={how.itemlist.find(i => key === active?.key)}
            onClick={() => setActive({ ...item, key })}>
            {item.name}
          </Step>
        ))}
      </InfoBlock>
    </Wrapper>
  )
}

const MediaWrapper = styled.div`
  display: flex;
  width: 50%;
  img {
    width: 100%;
    object-fit: contain;
  }
  video {
    width: 100%;
    object-fit: contain;
  }
`

const Step = styled.div`
  position: relative;
  color: ${theme.colors.black};
  background: none;
  border: none;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    width: 88%;
    left: 0;
    bottom: -3px;
    border-bottom: ${({ active }) =>
      active ? 'none' : `2px dashed ${theme.colors.black}`};
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
`

const InfoBlock = styled(FlexCol)`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h3 {
    font-size: 24px;
  }
`

const Wrapper = styled(FlexRow)`
  height: 100%;
  width: 100%;
  background: #f2f2f2;
  gap: 1rem;
  justify-content: flex-start;
  ${() => theme.mqMax('lg')} {
    flex-direction: column;
    ${MediaWrapper}, ${InfoBlock} {
      width: 100%;
      height: 50%;
    }

    ${InfoBlock} {
      margin-left: 1rem;
    }

    padding-bottom: 2rem;
  }
`

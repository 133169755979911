import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import { theme } from '../../assets/theme'

export const Back = ({ from }) => {
  return (
    <SLink to={from?.path}>
      <Button>{from?.title}</Button>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="8"
        viewBox="0 0 21 8"
        fill="none">
        <path
          d="M0.646446 4.35355C0.451185 4.15829 0.451185 3.84171 0.646446 3.64645L3.82843 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.7308 0.659728 4.7308 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.7308 7.02369 4.7308 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82843 7.53553L0.646446 4.35355ZM21 4.5H1V3.5H21V4.5Z"
          fill="#252259"
        />
      </svg>
    </SLink>
  )
}

const SLink = styled(Link)`
  text-decoration: none;
  display: flex;
  gap: 0.3rem;
  align-items: center;

  svg {
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
    path {
      fill: ${theme.colors.black};
    }
  }
`

const Button = styled.span`
  position: relative;
  color: ${theme.colors.black};
  background: none;
  border: none;
  cursor: pointer;
  order: 1;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    bottom: -3px;
    height: 1px;
    background: ${theme.colors.black};
  }

  &:hover {
    transform: translateX(3px);

    & + svg {
      transform: translateX(-3px);
    }
  }
`

import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import { Dropdown } from '..'

export const Menu = ({ menu }) => {
  return (
    <nav>
      <MenuWrapper>
        {menu?.map(({ id, path, label, children }) => (
          <li key={id}>
            {!children?.length ? (
              <MenuItem activeStyle={{ textDecoration: 'underline' }} to={path}>
                {label}
              </MenuItem>
            ) : (
              <Dropdown {...{ label, children }} />
            )}
          </li>
        ))}
      </MenuWrapper>
    </nav>
  )
}

const MenuWrapper = styled.ul`
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 0;
  margin-top: 2rem;
  padding: 0;
`

export const MenuItem = styled(Link)`
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../layouts'
import { PaginationList, SingleItem } from '../components'

export const locales = {
  ru: {
    'Святые места Константинополя': 'Святые места Константинополя',
    'Смотреть еще': 'Смотреть еще',
    news: `Последние новости`,
    'Смотреть все новости': `Смотреть все новости`,
    'К стихам': 'К стихам',
    'main-page': `Главная`,
    'Похожие места': `Похожие места`,
    'Заполните имя': 'Заполните имя',
    'Заполните email': 'Заполните email',
    'Введите текст письма': 'Введите текст письма',
    'Письмо успешно отправлено': 'Письмо успешно отправлено',
    'Ошибка. Повторите позже': 'Ошибка. Повторите позже',
    'Напишите нам': 'Напишите нам',
    'send-quote':
      'Вы можете к нам обратится по любому вопросу написав нам на почту',
    first_name: 'Имя',
    email: 'E-mail',
    'Отправить сообщение': 'Отправить сообщение',
    'Как пройти?': 'Как пройти?',
    'Все новости': 'Все новости',
    'Похожие новости': 'Похожие новости',
    courtyard: 'Подворье',
    menu: 'Меню',
    footer: `© 2022 Подворье Афонского Русского Свято-Пантелеимонова Монастыря в
    Стамбуле. Все права защищены`,
    details: 'Подробнее',
    'Расписание богослужений': 'Расписание богослужений',
    'Напишите ваш вопрос...': 'Напишите ваш вопрос...',
    'Читать далее': 'Читать далее',
  },
  tr: {
    'Святые места Константинополя': `Konstantinopolis'in kutsal yerleri`,
    'Смотреть еще': `Daha fazla izle`,
    news: `Haberler`,
    'Смотреть все новости': `Tüm haberleri gör`,
    'К стихам': 'Şiir için',
    'main-page': `Ana sayfa`,
    'Похожие места': `Benzer yerler`,
    'Заполните имя': 'Adını doldurun',
    'Заполните email': 'E-postayı doldurun',
    'Введите текст письма': 'Mektubun metnini girin',
    'Письмо успешно отправлено': 'E-posta başarıyla gönderildi',
    'Ошибка. Повторите позже': 'Hatası. Lütfen daha sonra tekrar deneyiniz',
    'Напишите нам': 'Bize yazın',
    'send-quote':
      'Herhangi bir sorunuzda bize mail yoluyla yazarak iletişime geçebilirsiniz',
    first_name: 'Ad',
    email: 'E-posta',
    'Отправить сообщение': 'Mesaj gönder',
    'Как пройти?': 'Nasıl geçilir?',
    'Все новости': 'Tüm haberler',
    'Похожие новости': 'İlgili haberler',
    courtyard: 'Bileşik',
    menu: 'Menü',
    footer: `© 2022 Athos Rus St. Panteleimon Manastırı Yerleşkesi
    İstanbul. Tüm hakları saklıdır`,
    details: 'Daha fazla',
    'Расписание богослужений': 'Hizmet programı',
    'Напишите ваш вопрос...': 'Sorunuzu yazın...',
    'Читать далее': 'Devamını oku',
  },
}

export const useTranslation = locale => {
  const l = locale
  return {
    t: name => {
      return locales[l][name]
    },
  }
}

const Home = ({ data, pageContext: context, location }) => {
  const { t } = useTranslation(context?.locale)
  return (
    <Layout
      data={data?.strapiMain}
      menu={context?.menu}
      locale={context?.locale}
      meta={context?.meta}
      location={location}>
      <SingleItem
        locale={context?.locale}
        path={`/${context.locale}/podvorie/history`}
        data={data.strapiPodvorie}></SingleItem>
      <PaginationList
        title={t('Святые места Константинополя')}
        entity="holy-places"
        locale={context.locale}
        paginationButton={t('Смотреть еще')}
        items={data.allStrapiHolyPlace.edges}></PaginationList>
      <SingleItem
        locale={context?.locale}
        path={`/${context.locale}/monastery`}
        reverse
        data={data.strapiMonastery}></SingleItem>
      <PaginationList
        title={t('news')}
        paginationButton={t('Смотреть еще')}
        entity="articles"
        locale={context.locale}
        items={data.allStrapiArticle.edges}></PaginationList>
    </Layout>
  )
}

export const query = graphql`
  query IndexTemplate($locale: String!) {
    allStrapiHolyPlace(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          description
        }
      }
    }
    allStrapiArticle(filter: { locale: { eq: $locale } }) {
      edges {
        node {
          id
          title
          created_at
          image {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          description
        }
      }
    }
    strapiPodvorie(locale: { eq: $locale }) {
      id
      title
      subtitle
      preview {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    strapiMonastery(locale: { eq: $locale }) {
      id
      title
      subtitle
      preview {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    strapiMain(locale: { eq: $locale }) {
      title
      title_description
      background {
        url
      }
      picture {
        url
      }
      button
    }
  }
`

export default Home

import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import { theme } from '../../assets/theme'

export const Pagination = ({ context }) => {
  console.log(context)
  const renderPages = () => {
    const pages = []
    for (let i = 1; i <= context?.numberOfPages; i++) {
      pages.push(i)
    }

    return pages.map(item => (
      <PageLink
        active={item === '1' || item === context?.humanPageNumber}
        to={item === 1 ? context?.prefix : `${context?.prefix}/${item}`}>
        {item}
      </PageLink>
    ))
  }

  return (
    <PaginationWrapper>
      {context?.previousPagePath?.length ? (
        <PrevLink to={context?.previousPagePath}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="8"
            viewBox="0 0 21 8"
            fill="none">
            <path
              d="M0.646446 4.35355C0.451185 4.15829 0.451185 3.84171 0.646446 3.64645L3.82843 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.7308 0.659728 4.7308 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.7308 7.02369 4.7308 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82843 7.53553L0.646446 4.35355ZM21 4.5H1V3.5H21V4.5Z"
              fill="#252259"
            />
          </svg>
        </PrevLink>
      ) : (
        ''
      )}
      <PaginationNumbers>{renderPages()}</PaginationNumbers>
      {context?.humanPageNumber < context?.numberOfPages ? (
        <NextLink to={context?.nextPagePath}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="21"
            height="8"
            viewBox="0 0 21 8"
            fill="none">
            <path
              d="M0.646446 4.35355C0.451185 4.15829 0.451185 3.84171 0.646446 3.64645L3.82843 0.464466C4.02369 0.269204 4.34027 0.269204 4.53553 0.464466C4.7308 0.659728 4.7308 0.976311 4.53553 1.17157L1.70711 4L4.53553 6.82843C4.7308 7.02369 4.7308 7.34027 4.53553 7.53553C4.34027 7.7308 4.02369 7.7308 3.82843 7.53553L0.646446 4.35355ZM21 4.5H1V3.5H21V4.5Z"
              fill="#252259"
            />
          </svg>
        </NextLink>
      ) : (
        ''
      )}
    </PaginationWrapper>
  )
}

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

const PaginationNumbers = styled.div`
  display: flex;
  gap: 0.5rem;
`

const PageLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.black};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  &:hover {
    /* transform: scale(1.05); */
    color: ${theme.colors.primary};
  }

  ${({ active }) =>
    active
      ? css`
          background: ${theme.colors.primary};
          &:hover {
            color: ${theme.colors.black};
          }
        `
      : css``};
`

const PrevLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  cursor: pointer;
  svg {
    transform: rotate(0deg) translateY(-2px);
  }
`
const NextLink = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  cursor: pointer;
  svg {
    transform: rotate(180deg) translateY(2px);
  }
`

import styled from '@emotion/styled'
import React, { useState } from 'react'
import { Container, List, PageTitle } from '..'
import { theme } from '../../assets/theme'
import { useTranslation } from '../../views'

export const PaginationList = ({
  items,
  paginationButton,
  title,
  entity,
  locale,
  children,
}) => {
  const [limit, setLimit] = useState(10)
  const { t } = useTranslation(locale)
  return (
    <Pagination>
      <Container>
        <PageTitle>{title}</PageTitle>
      </Container>
      <PaginationContainer>
        <List locale={locale} entity={entity} items={items.slice(0, limit)} />
        {limit < items.length ? (
          <Button onClick={() => setLimit(limit + 10)}>
            {paginationButton || t('Смотреть еще')}
          </Button>
        ) : (
          ''
        )}
      </PaginationContainer>
      {children ? <PaginationContainer>{children}</PaginationContainer> : ''}
    </Pagination>
  )
}

const Pagination = styled.div`
  background: #f2f2f2;
  padding: 2rem 0;

  ${() => theme.mq('lg')} {
    padding: 4rem 0;
  }
`

const PaginationContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Button = styled.button`
  background: #cfc89c;
  border: none;
  min-width: 123px;
  padding: 0 30px;
  height: 50px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
`

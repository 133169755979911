import styled from '@emotion/styled'
import React from 'react'
import { Map, ImageContainer, ItemImage, Modal, useModal } from '.'
import { Gallery } from '..'
import { theme } from '../../assets/theme'

export const Place = ({ shrine }) => {
  const { onToggle, isOpen } = useModal()
  return (
    <Item>
      <Preview>
        <ImageContainer>
          <ItemImage
            image={shrine.image.localFile.childImageSharp.gatsbyImageData}
          />
        </ImageContainer>
        <Map coords={shrine.coords} />
      </Preview>
      <h2>{shrine.title}</h2>
      <p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="23"
          viewBox="0 0 17 23"
          fill="none">
          <path
            d="M8.5 23C8.38856 23 8.27711 22.9597 8.18739 22.8802C7.85306 22.5802 0 15.4713 0 8.625C0 3.86975 3.81272 0 8.5 0C13.1873 0 17 3.86975 17 8.625C17 15.4713 9.14694 22.5802 8.81261 22.8802C8.72289 22.9597 8.61144 23 8.5 23ZM8.5 0.958333C4.33406 0.958333 0.944444 4.39779 0.944444 8.625C0.944444 14.2955 7.03706 20.469 8.5 21.8692C9.96294 20.469 16.0556 14.2964 16.0556 8.625C16.0556 4.39779 12.6659 0.958333 8.5 0.958333Z"
            fill="#1C1C1C"
          />
          <path
            d="M9.5625 15.0938H7.4375C7.14425 15.0938 6.90625 14.8523 6.90625 14.5547V10.7813H4.78125C4.48801 10.7813 4.25001 10.5398 4.25001 10.2422V8.08594C4.25001 7.78838 4.48801 7.54688 4.78125 7.54688H6.90625V5.92969C6.90625 5.63213 7.14425 5.39063 7.4375 5.39063H9.5625C9.85575 5.39063 10.0937 5.63213 10.0937 5.92969V7.54688H12.2187C12.512 7.54688 12.75 7.78838 12.75 8.08594V10.2422C12.75 10.5398 12.512 10.7813 12.2187 10.7813H10.0937V14.5547C10.0937 14.8523 9.85575 15.0938 9.5625 15.0938ZM7.96875 14.0156H9.03125V10.2422C9.03125 9.94463 9.26925 9.70313 9.5625 9.70313H11.6875V8.625H9.5625C9.26925 8.625 9.03125 8.3835 9.03125 8.08594V6.46876H7.96875V8.08594C7.96875 8.3835 7.73075 8.625 7.4375 8.625H5.3125V9.70313H7.4375C7.73075 9.70313 7.96875 9.94463 7.96875 10.2422V14.0156Z"
            fill="#1C1C1C"
          />
        </svg>
        <span>{shrine.address}</span>
      </p>
      {shrine?.how_lists?.map(how => (
        <>
          <SButton onClick={() => onToggle(true)}>{how?.title}</SButton>
          <Modal
            width="80%"
            height="60%"
            open={isOpen}
            onClose={() => onToggle(false)}>
            <Gallery how={how} />
          </Modal>
        </>
      ))}
    </Item>
  )
}

const Preview = styled.div`
  display: flex;
  height: 350px;
  gap: 1rem;

  ${ImageContainer} {
    height: 100%;
  }

  ${theme.mqMax('lg')} {
    flex-direction: column;
  }
`

const Item = styled.div`
  h2 {
    font-size: 24px;
  }

  p {
    display: flex;
    gap: 0.3rem;
  }
`

const SButton = styled.button`
  position: relative;
  color: #857b4c;
  background: none;
  border: none;
  cursor: pointer;

  &:after {
    content: '';
    position: absolute;
    width: 88%;
    left: 6%;
    bottom: -3px;
    height: 2px;
    background: #857b4c;
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  &:hover {
    &:after {
      transform: translateY(-3px);
    }
  }
`
